var uiRichDropdown = function (params) {

    //global variables set from outside:
    var _params = {
        component: jQuery('.component-rich-dropdown')
    };
    //global variables set from inside:
    var _global = {
        event: 'click',
        activeClass: 'is-active',
        html: jQuery('html'),
        checkedItems: 0
    }

    jQuery.extend(_params, params);

    //extend params
    _params.component.trigger = _params.component.find('.dropdown-toggle');
    _params.component.dropdown = _params.component.find('.dropdown');
    _params.checkboxes = _params.component.dropdown.find('input[type=checkbox]');

    var _this = {};

    _this.init = function () {
        //console.log("uiRichDropdown :: init");

        //eventlisteners
        _params.component.dropdown.on(_global.event, stopPropagationFunction);
        _params.component.trigger.on(_global.event, _this.toggleVisibility);
        _global.html.on(_global.event, _this.toggleVisibilityHtml);

    };


    //public methods
    _this.toggleVisibility = function (event) {
        event.stopPropagation();
        if (_params.component.hasClass('is-active')) closeDropdown();
        else openDropdown();

    }

    _this.toggleVisibilityHtml = function () {
        if (_params.component.hasClass('is-active')) closeDropdown();
    }

    //private methods
    var openDropdown = function () {
        _params.component.addClass('is-active');
    }

    var closeDropdown = function () {
        _params.component.removeClass('is-active');
        _global.checkedItems = 0;

        var translation = _params.component.attr('data-recipients');

        //everytime we close, we count the checked boxes
        _global.checkedItems = _params.component.dropdown.find('input:checkbox:checked').length;

        if (_global.checkedItems > 0) {

            _params.component.trigger
                .find('.label').html('' + _global.checkedItems + ' ' + translation);

        }

    }

    var stopPropagationFunction = function (event) {
        event.stopPropagation();
    }

    _this.init();
    return _this;

}
jQuery(function () {

    jQuery('.component-rich-dropdown').each(function (k, v) {
        uiRichDropdown({ component: jQuery(v) });
    });

});
