
var UiMobileTileComponent = function (params) {

    //global variables set from outside:
    var _params = {
        component: jQuery('.component-ui-mobile-tile'),
        mobile: 570,
        resizeTimeout: 100
    };

    //global variables set from inside:
    var _global = {
        window: jQuery(window),
        resizeTimer : {},
        resize: 'resize',
        image: jQuery('<img/>'),
        attribute: 'src',
        mobileAttribute: 'data-mobile-image',
        desktopAttribute: 'data-desktop-image'
    }

    jQuery.extend(_params, params);

    //params extends:
    _params.component.thumb = _params.component.find('.ui-thumbnail');



    var _this = {};

    _this.init = function () {
        //console.log("uiMobileTileComponent :: init");

        //init

        _this.initImage();

        //event listeners
        _global.window.on(_global.resize, function (e) {

            clearTimeout(_global.resizeTimer);

            _global.resizeTimer = setTimeout(function () {

                //resize events go here
                if (_checkIfMobile()) _this.switchMobileImage();
                else _this.switchDesktopImage();

            }, _params.resizeTimeout);
        });

    };

    //private methods
    var _checkIfMobile = function () {
        return (_global.window.innerWidth() <= _params.mobile);
    }

    //public methods

    _this.initImage = function () {

        if (_checkIfMobile()) _global.image.attr(_global.attribute, _params.component.thumb.attr(_global.mobileAttribute));
        else _global.image.attr(_global.attribute, _params.component.thumb.attr(_global.desktopAttribute));

        //append the image to thumnail:
        _params.component.thumb.append(_global.image);
    }

    _this.switchMobileImage = function () {
        //switch that image src
        _params.component.thumb.find('img').attr(_global.attribute, _params.component.thumb.attr(_global.mobileAttribute));
    }

    _this.switchDesktopImage = function () {
        //switch that image src
        _params.component.thumb.find('img').attr(_global.attribute, _params.component.thumb.attr(_global.desktopAttribute));
    }

    _this.init();
    return _this;

}
jQuery(function () {

    if (jQuery('.sfPageEditor').length > 0) {
        return;
    }

    jQuery('.component-ui-mobile-tile').each(function (k, v) {
        UiMobileTileComponent({ component: jQuery(v) });
    });



});
