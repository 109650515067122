var uiVideoTile = function (params) {

    //global variables set from outside:
    var _params = {
        component: jQuery('.ui-video-tile'),
        globalEvent: 'click'
    };
    //global variables set from inside:
    var _global = {
        videoType : ''
    }

    jQuery.extend(_params, params);

    _params.component.trigger = _params.component.find('.tile-content');

    var _this = {};

    _this.init = function () {
        //console.log("uiVideoTile :: init");
        //init

        //check the video type
        _global.videoType = _params.component.attr('data-type');

        //add event listeners
        _params.component.on(_params.globalEvent, _this.hideThumbnail);

    };


    //public methods
    _this.hideThumbnail = function (event) {
        var target = jQuery(event.currentTarget);
        if (target.hasClass('opened')) return;

        var video = target.find('iframe');
        target.addClass('opened');

        switch (_global.videoType) {
            case 'youtube':
                initYoutube(video);
                break;
            case 'vimeo':
                initVimeo(video);
                break;
            default:
                alert('Error');
        }


    }

    //private methods
    var initYoutube = function (video) {
        video[0].src += "?autoplay=1";
    }

    var initVimeo = function (video) {
        video[0].src += "?autoplay=true";
    }

    _this.init();
    return _this;

}
jQuery(function () {

    jQuery('.ui-video-tile').each(function (k, v) {
        uiVideoTile({ component: jQuery(v) });
    });

});
