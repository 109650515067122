window.onload = function () {
    //console.log(jQuery(".file-upload-wrapper .browse-file-btn"));
    jQuery(".file-upload-wrapper .browse-file-btn").bind("click", function () {
        var parentWrapper = jQuery(this).parent(".file-upload-wrapper");
        jQuery(parentWrapper).find(".hidden-file-upload-button").click();
    })
    jQuery(".hidden-file-upload-button").bind("change", function (event) {
        var parentWrapper = jQuery(this).parent(".file-upload-wrapper");
        jQuery(parentWrapper).find(".filename-textbox").val(jQuery(this).val().split('\\').pop());
    })
}
