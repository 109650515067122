var uiInpageNavigation = function (params) {

    //global variables set from outside:
    var _params = {
        component: jQuery('.component-inpage-navigation'),
        headerItems: jQuery('.component-inpage-heading'),
        mainNaviHeight: jQuery('.ui-navigation').innerHeight(),
        scrollTimeout: 100
    };
    //global variables set from inside:
    var _global = {
        event: 'click',
        scroll: 'scroll',
        window: jQuery(window),
        html: jQuery('html, body'),
        document: jQuery(document),
        placeholder: jQuery('<div class="in-page-placeholder"></div>'),
        placeholderOffset: 0,
        time: 400
    }

    jQuery.extend(_params, params);

    _params.component.trigger = _params.component.find('.trigger');

    //extend params
    _params.componentUl = _params.component.find('ul');

    var _this = {};

    _this.init = function () {
        //console.log("uiInpageNavigation :: init");

        //init
        _this.createInpageNavi();

        //attach event listeners
        _params.componentUl.find('a').on(_global.event, _this.scrollToItem);
        _params.component.trigger.on(_global.event, _this.toggleVisibility);
        _global.window.on('scroll', function (e) {
            /*var target = e.target;

            if (!jQuery(target).is('.component-inpage-navigation') && !jQuery(target).parents().is('.component-inpage-navigation')) {
                _params.component.removeClass('show');
            }*/

            clearTimeout(jQuery.data(this, 'scrollInpage'));
            jQuery.data(this, 'scrollInpage', setTimeout(function () {

                _this.stickInpage();

            }, _params.scrollTimeout));
        });

    };


    //public methods
    _this.createInpageNavi = function () {

        _params.component.wrap(_global.placeholder);
        _global.placeholderOffset = _params.component.parents('.in-page-placeholder').offset().top;

        _params.headerItems.each(function (k, v) {
            jQuery(v).attr('data-id', 'section' + k);
            _params.componentUl.append('<li><a href="#" data-id="section' + k + '">' + jQuery(v).attr('data-navigation-text') + '</a></li>');
        });

        _params.component.parents('.in-page-placeholder').css('height', _params.component.height() + 20);

        //_params.component.toggleClass('show');
    }

    _this.scrollToItem = function (event) {

        var target = jQuery(event.currentTarget),
            targetId = target.attr('data-id');

        //find the correct target heading

        _params.headerItems.each(function (k, v) {
            if (jQuery(v).attr('data-id') == targetId) {
                scrollView(jQuery(v).offset().top);
                //_params.component.toggleClass('show');
            }
        });

    }

    _this.toggleVisibility = function () {
        _params.component.toggleClass('body-collapse');
    }

    _this.stickInpage = function () {
        if (jQuery(window).scrollTop() > _global.placeholderOffset) {
            _params.component.addClass('stickme');
            _params.component.addClass('body-collapse');
        } else {
            if (jQuery(window).scrollTop() <= _global.placeholderOffset) {
                _params.component.removeClass('stickme');
                _params.component.removeClass('body-collapse');
            }
        }
    }

    //private methods
    var scrollView = function (offset) {

        _global.html.animate({ scrollTop: offset - _params.mainNaviHeight }, _global.time);

    }

    _this.init();
    return _this;

}
jQuery(function () {

    jQuery('.component-inpage-navigation').each(function (k, v) {
        uiInpageNavigation({ component: jQuery(v) });
    });

});
