var uiSelect = function (params) {

    //global variables set from outside:
    var _params = {
        component: jQuery('.component-dropdown')
    };
    //global variables set from inside:
    var _global = {
        trigger: jQuery('<div class="dropdown-toggle ui-button clean"></div>'),
        dropdown: jQuery('<div class="dropdown"></div>'),
        dropdownUl: jQuery('<ul class="option-list"></ul>'),
        componentWidth: 0,
        event: 'click'
    }

    jQuery.extend(_params, params);

    //extend params
    _params.component.select = _params.component.find('select');
    _params.component.select.options = _params.component.select.find('option');

    var _this = {};

    _this.init = function () {
        //console.log("uiSelect :: init");

        //init
        _this.createHTMLStructure();
        _global.htmlOptions = _global.dropdownUl.find('.list-item');

        //attach event listeners
        _global.trigger.on(_global.event, _this.triggerTarget);
        _global.htmlOptions.on(_global.event, _this.triggerOptions);

    };


    //public methods
    _this.createHTMLStructure = function () {
        //append the trigger to component. Give it first option as default.
        _global.trigger.append('<span class="label">' + _params.component.select.find('option:first').text() + '</span>');

        _params.component.append(_global.trigger);
        _params.component.append(_global.dropdown);

        //create ul with options:
        _params.component.select.options.each(function (k, v) {

            if (k == 0) active = 'is-active';
            else active = '';

            _global.dropdownUl
                .append('<li class="list-item '+ active +'"><button class="option" data-value="' + jQuery(v).val() + '" type="button"><span class="label">' + jQuery(v).text() + '</span></button></li>')

            _global.dropdown.append(_global.dropdownUl);
        });

        setTimeout(function () { _global.componentWidth = _params.component.find('.option-list').innerWidth(); }, 200)

    }

    _this.triggerTarget = function () {
        _params.component.toggleClass('is-active');
        if (_params.component.hasClass('is-active')) grow();
        else shrink();
    }

    _this.triggerOptions = function (event) {
        var target = jQuery(event.currentTarget),
            dataValue = '',
            dataHtml = '';
        target.siblings().removeClass('is-active');
        target.addClass('is-active');
        dataValue = String(target.find('button').attr('data-value'));
        dataHtml = String(target.find('button span').html());


        //select the right option in the select
        _params.component.find('select option[value="' + dataValue + '"]').prop('selected', true);
        _params.component.find('select').trigger('change');
        _global.trigger.find('.label').html(dataHtml);

        //close the dropdown
        _params.component.removeClass('is-active');
        setTimeout(shrink, 400);

    }

    //private methods
    var grow = function () {
        _params.component.css('min-width', _global.componentWidth);
    }

    var shrink = function () {
        _params.component.css('min-width', 0);
    }

    _this.init();
    return _this;

}
jQuery(function () {

    jQuery('.component-dropdown').each(function (k, v) {
        uiSelect({ component: jQuery(v) });
    });

});
